<template>
  <div class="home-container">
    <div class="header linear-bg" style="height: auto;" v-if="this.$store.getters.getBaseInfo.ico">
      <img
                class=""
                style="height: 50px;padding: 0 15px;display: block;"
                :src="
                  this.$store.getters.getBaseInfo.ico"
              />
              <div style="flex: 1;"></div>
              <van-icon name="service-o" size="24" color="#fff" style="margin-right: 10px;" v-if="iskefu == '1'" @click="toKefu" />
    </div>

  <div class="mask" v-if="isLoading && video_login ==1 && !token">
    <van-loading type="spinner" size="80px" />
  </div>
    <div class="linear-bg"></div>
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v,key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <div class="notice-bar">
        <van-notice-bar
            class="notice-swipe"
            left-icon="bullhorn-o"
            background="#ffffff"
            color="#775fd9"
            :text="notice"
        />
        <div class="linear-gradient"></div>
      </div>
      <div class="hot-game">
        <div class="hot-title-div">
          <div>
            <span>{{$t('index.task')}}</span></div>
          <div @click="gotoMenu('/Game')">
            <span>{{$t('index.more')}}</span>
            <van-icon name="arrow" color="#c24491" />
          </div>
        </div>
        <div class="hot-items-div">
          <van-grid :border = false :column-num="3" :icon-size="20">
            <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
              <van-image class="game_item_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <span>{{v.name}}</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')" :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <div class="hot-recommend">
          <div class="hot-title-div">
            <div>
              <span>{{$t('index.hot')}}</span></div>
            <div>
              <span @click="gotoMenu('/Video')">{{$t('index.more')}}</span>
              <van-icon name="arrow" color="#c24491" />
            </div>
          </div>
          <div class="movie_list_0">
            <swiper class="movie_swiper" :options="movielistSwiperOption">
              <swiper-slide v-for="(v,key) in movielist_0" :key="key" >
                <van-image class="movie_cover" @click="toPlayVideo(v.id)"  round :src="v.cover">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <img class="hot" v-if="key === 0" src="/img/home/no1.png">
                <img class="hot" v-if="key === 1" src="/img/home/no2.png">
                <img class="hot" v-if="key === 2" src="/img/home/no3.png">
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{v.title}}</span>
                    <div class="van-count-down">{{v.time}}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="hot-title-div">
            <div>
              <span>{{$t('index.more')}}</span></div>
            <div @click="gotoMenu('/Video')">
              <span>{{$t('index.recmonmand')}}</span>
              <van-icon name="arrow" color="#c24491" />
            </div>
          </div>
          <div class="movie_list_1">
            <div class="movie-list-item" v-for="(v,key) in movielist_1" :key="key" @click="toPlayVideo(v.id)">
              <img  class="cover_img" :data-src="v.cover"  alt=""  v-lazy="v.cover">
              <div class="movie-list-item-bottom">
                <div class="movie-time-div">
                  <span>{{v.title}}</span>
                  <span>{{$t('video.play')}}:{{v.count}}</span>
                </div>
              </div>
            </div>
            <div class="hot-recommend-more" @click="gotoMenu('/Video')">{{$t('index.more')}}</div>
          </div>
        </div>
      </van-pull-refresh> -->



      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')" :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <div class="hot-title-div" style="margin-bottom: -20px;">
            <div>
              <span>{{$t('index.hot')}}</span></div>
          </div>
        <van-list
                    v-model="loading"
                    :finished="finished"
                    :offset="50"
                    :immediate-check="false"
                    :finished-text="$t('video.no_more')"
                    @load="onLoad"
                >
                <div class="movie_list_n" v-if="movielist_1.length > 0">
            <div class="movie-list-n-item" v-for="(v,key) in movielist_1" :key="key" @click="toPlayVideo(v.id)">
              <div class="movie-list-n-title">
                {{v.name}}
              </div>
              <div class="movie-list-n-img">
                <van-image
                  fit="cover"
                  :src="v.img"
                  class="movie-list-n-img" :data-src="v.img"  alt=""  v-lazy="v.img"
                />
                <!-- <img> -->
                <!-- <div class="movie-list-n-lab">{{v.address}}</div> -->
              </div>
              <div class="movie-list-n-item-bottom">
                <div class="movie-n-time-div">
                <img class="movie-list-ico-loves" v-for="item in 6" :key="item" src="../images/nHome/ico-love.png"  alt="" >
                  <div class="movie-list-notice">
                    <img class="movie-list-ico-notice" src="../images/nHome/ico-notice.png"  alt="" >
                    {{ v.comment_total }}
                  </div>
                  <div class="movie-list-addr">
                    <img class="movie-list-ico-addr" src="../images/nHome/ico-addr.png"  alt="" >
                    {{v.city_name}}
                  </div>
                  <div class="movie-list-money">
                    <img class="movie-list-ico-money" src="../images/nHome/ico-money.png"  alt="" >{{v.price}}</div>

                </div>
              </div>
            </div>
          </div>
        </van-list>

        </van-pull-refresh>
    </div>
    <van-popup
        v-model="pop_content"
        round
        closeable
        close-icon="close"
        style="width:70%;height: 50%;font-size: 24px;color: #000;"
      >
      <div class="pop_content-content" v-html="pop_content_txt"></div>


      </van-popup>


    <img  class="luckDraw" v-if="isluckdraw == '1'" @click="toLuckDraw" src="@/assets/luckdraw.png" alt="">
<img class="kefu" v-if="iskefu == '1'" src="@/assets/kefu.png" @click="toKefu" alt="" srcset="">
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: this.$t("index.loading"),
      banners: [{}],
      basicData:[],
      gameitem: [{},{},{},{}],
      movielist_0: [{},{},{},{}],
      movielist_1: [],
      loading: false,
      finished: false,
      isLoading: true,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed:800,
        autoplay:true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows : true
        }
      },
      video_login:this.$store.getters.getBaseInfo.video_login||1,
      token:localStorage.getItem('token'),
      isluckdraw:null,
      pop_content_txt:'',
      pop_content:false,
      kefu:'',
      iskefu:0,

      coordinate:{
          client:{},
          elePosition:{}
        }

    };
  },
  methods:{
    toKefu(){

window.location.href = this.kefu
},
touchStartHandle(refName,e){
        let element = e.targetTouches[0]
        // 记录点击的坐标
        this.coordinate.client = {
          x:element.clientX,
          y:element.clientY
        }
        // 记录需要移动的元素坐标
        this.coordinate.elePosition.left = this.$refs[refName].offsetLeft
        this.coordinate.elePosition.top = this.$refs[refName].offsetTop
      },
      drag(refName,e) {
        let element = e.targetTouches[0]
        // 根据初始client位置计算移动距离（元素移动位置+光标移动后的位置-光标点击时的初始位置）
        let x = this.coordinate.elePosition.left +(element.clientX - this.coordinate.client.x)
        let y = this.coordinate.elePosition.top +(element.clientY - this.coordinate.client.y)
        // 限制可移动距离，不超出可视区域
        x= x<=0 ? 0 : x>=innerWidth-this.$refs[refName].offsetWidth ? innerWidth - this.$refs[refName].offsetWidth : x
        // 50是头部的高度
        y= y<=50 ? 50 : x>=innerHeight-this.$refs[refName].offsetHeight ? innerHeight - this.$refs[refName].offsetHeight : y
        // 移动当前元素
        this.$refs[refName].style.left = x + 'px'
        this.$refs[refName].style.top = y + 'px'
      },
    toLuckDraw(){
      this.$router.push({path:'/luckDraw'})
    },
    gotoMenu(router){
      this.$router.replace(router)
    },
    toLottery(key,id){
      if(!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1){
        this.$router.push({path:'/Login'})
      }else {
        if (id) {
          this.$router.push({path:'/Lottery?key='+key+"&id="+id})
        }
      }

    },
    // toPlayVideo(id) {

    //   if(!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1){
    //     this.$router.push({path:'/Login'})
    //   } else {
    //     if (id) {
    //      this.$router.push({path:'/PlayVideo?id='+id})
    //     }
    //   }
    // },

    toPlayVideo(id) {

if(!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1){
  this.$router.push({path:'/Login'})
} else {
  if (id) {
   this.$router.push({path:'/nHomeDetail?id='+id})
  }
}
},
    // onRefresh() {
    //   setTimeout(() => {
    //     this.getBasicConfig();
    //     this.isLoading = false;
    //     this.$toast(this.$t('reservation.refresh'));
    //   }, 500);
    // },

    onRefresh() {
      setTimeout(() => {
        this.page = 1
        // this.movielist_1 = []
        this.getNhomeList();
        this.isLoading = false;
        this.$toast(this.$t('reservation.refresh'));
      }, 500);
    },
    onLoad() {
      this.loading = true;
      this.getNhomeList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.movielist_1 = [];
          this.refreshing = false;
        }
        // this.loading = false;
        if (this.movielist_1.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res => {
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        // this.getMovieList_1(this.basicData);//获取首页视频1

        this.$nextTick(() => {
          this.lazyLoadImg()
        })
      })

    },
    getNotice(data){
      this.notice = data.notice || '';
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res => {
        this.gameitem = res.data
      })
    },
    getMovieList_0(data){
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data){
      this.movielist_1 = data.movielist_1
    },
    getBanner(data){
      this.banners = data.banners;
    },
    query(selector) {
     return Array.from(document.querySelectorAll(selector));
    },
    lazyLoadImg() {
      if (this.movielist_1.length > 0) {
      this.query("img[data-src]").forEach((img) => {
          const rect = img.getBoundingClientRect();
          if (rect.top < window.innerHeight) {
            img.src = img.dataset.src;
            // 我们是通过img[data-src]查找所有img标签的，渲染后就删除data-src可减少forEach循环的计算成本
            img.removeAttribute("data-src");
          }
        });
      }
    },

    getNhomeList() {
      this.$http({
        method: 'get',
        url: 'nHomeList',
        data: { page: this.page },
      }).then(res => {
        console.log(res.data,999)
        this.loading = false;
        if (this.page == 1) {
          this.movielist_1 = res.data;
        } else {

          this.movielist_1 = this.movielist_1.concat(res.data);
        }
        if (res.data.length == 0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        this.count = res.data.count;
        this.page++;
      })

    }
  },
  mounted() {
    let that = this;
    window.addEventListener("scroll", () => {
      // 这里做一个 节流 操作
      that.lazyLoadImg()
    });
  },
  created() {
    this.$http({
        method: 'get',
        url: 'base_info'
    }).then(res => {
      if (res.code == 200) {

        if(!localStorage.getItem('token') && res.data.video_login == 1){
         return this.$router.push({path:'/Login'})
        }
    this.getNhomeList()
        console.log(res)
        this.isLoading = false
        this.isluckdraw = res.data.isluckdraw
        this.pop_content_txt = res.data.pop_content
        this.kefu = res.data.kefu
        this.iskefu = res.data.iskefu
        if(res.data.ispop == 1){
          this.pop_content = true
        }


        this.$store.commit('setBaseInfoValue', res.data);
    this.getBasicConfig();
        }
      })
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size:30px;
@movie-list-item-bottom-size:25px;
.linear-bg{
  height: 200px;
  display: flex;
  align-items: center;
  background: linear-gradient(
      270deg,#c24491,#775fd9);
}
.home-container{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}
.linear-gradient{
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}
::v-deep .van-notice-bar__left-icon, .van-notice-bar__right-icon {
  min-width:40px;
}
.notice-swipe{
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner{
  width: 100%;
  margin-top: -23%;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
.banner_img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game{
  width: 100%;
  height:  100%;
}
.hot-title-div{
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-title-div> div:first-child{
  width: 430px;
}
.hot-title-div div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hot-title-div>div:nth-child(2) span{
  font-size: 20px;
  color: #979799;
}
.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #c24491;
}

.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 700;
  color: #c24491;
}
.hot-title-div > div:nth-child(2) span{
  font-size: 25px;
  color: #c24491;
}
.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #775fd9;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div{
  margin-top: -3px;
}
.hot-game .hot-items-div span{
  margin-top: 10px;
  font-size: 24px;
  color: #c24491;
}
.hot-recommend{
  width: 100%;
  flex: 1;
  background-color: #f2f2f5;
}
.movie_swiper{
  .swiper-slide {
    width: 80%;
    height: 330px;
  }
}
.movie_list_0{
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover{
  border-radius: 10px;
  width:550px;
  height:330px
}
.movie_list_0 .movie-list-item-bottom{
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius:  0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span{
  font-size: 30px;
}
.movie_list_1{
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.movie_list_1 .movie-list-item .cover_img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.home-scroll{
  padding-bottom: 110px;
}
.movie_list_1 .movie-list-item{
  margin-bottom: 10px;
  width:335px;
  height:290px;
}

.movie_list_1 .movie-list-item-bottom{
  position: relative;
  width: 335px;
  bottom: 42px;

}
.movie_list_1 .movie-list-item:nth-child(odd){
  margin-right: 10px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}
.movie_list_1 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right:110px;
  font-size: 22px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more{
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #c24491;
  font-size: 30px;
}
.hot-items-div{
  padding: 12px 0;
}
.hot-items-div .game_item_img{
  width: 100px;
  height: 100px;
  margin-bottom: 16px;
}
::v-deep .hot-items-div .game_item_img .van-image__img{
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}
.mask{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.luckDraw{
  position: fixed;
  bottom: 160px;
  right: 40px;
  width:120px;
  height: 120px;
  border-radius: 50%;
  z-index: 999;
}
.kefu{
  position: fixed;
  bottom: 300px;
  right: 60px;
  width: 80px;
  height:80px;
  z-index: 999;
  // background: #fff;border-radius: 50px;
} 
/deep/.van-popup__close-icon{
  font-size: 50px;
}
.pop_content-content{
  font-size: 30px;
  color: #1c1e23;
  padding: 30px;
  margin-top: 40px;
}





.movie-list-n-img {
  width: 330px;
  height: 330px;
  position: relative;
}

.bd-info .info-model .model-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  background-color: #8f65fe;
  border-radius: 0.5rem;
  margin: 0.625rem;
  padding: 0.625rem;
}

.bd-info .info-model .model-item .item-pic {
  position: relative;
  width: 7.5rem;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 0.625rem;
}

.bd-info .info-model .model-item .item-info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: .75rem;
}

.bd-info .info-model .model-item .item-pic img {
  width: 7.5rem;
  height: 11.25rem;
  border: 0;
  vertical-align: middle;
}

.bd-info .info-model .model-item .item-info .name {
  margin-bottom: 0.625rem;
  margin-top: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag {
  margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag span {
  font-size: .75rem;
  color: #fff;
  display: inline-block;
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
  background-color: #ea92cf;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .info {
  margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .remark {
  // height: 2.625rem;
  line-height: 1.6;
  overflow: hidden;
  margin-bottom: 0.75rem;

  display: -webkit-box;

overflow: hidden;

white-space: normal !important;

text-overflow: ellipsis;

word-wrap:break-word;

-webkit-line-clamp:3;

-webkit-box-orient: vertical
}

.bd-info .info-model .model-item .item-info .call .van-button {
  width: 100%;
  height: 2.125rem;
  border-radius: 6.1875rem !important;
}

.bd-info .info-model .model-item .item-info .remark span {
  color: #ddd2fb;
  font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .name span {
  display: inline-block;
  color: #fff;
  font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .info span {
  color: #ddd2fb;
  font-size: .875rem;
}

.van-button--info,
.van-button--primary {
  border: none !important;
  background: -webkit-gradient(linear, left top, right top, from(#a082f0), to(#ea92cf)) !important;
  background: linear-gradient(90deg, #a082f0, #ea92cf) !important;
  border-radius: 5px !important;
}

.movie_list_n{
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  padding-bottom: 120px;
}
.movie-list-n-item{
 width: calc(50% - 11px);
 padding: 10px;
 background-color: #fff;
border: 1px solid #fff;
 color: #000;
 margin: 20px 5px;
 border-radius: 20px;
 padding: 10px;
}
.movie-list-n-item-bottom{

}
.movie-n-time-div{
margin-top: 20px;
}
.movie-list-n-title{
  // color: #f905e5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
  font-weight: bold;
}
.movie-list-n-img{
width: 330px;
height: 330px;
position: relative;
}
.movie-list-n-lab{
  display: inline;
  position: absolute;
  top: 15px;
  left: 15px;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;  background-color: #f905e5;
  width: 315px;
  overflow: hidden;
}
.movie-list-ico-loves{
  display: inline-block;
  width: 40px;
}


.movie-list-notice{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.movie-list-ico-notice{
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}
.movie-list-addr{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.movie-list-ico-addr{
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}
.movie-list-money{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.movie-list-ico-money{
  width: 30px;
  display: inline-block;
  margin-right: 10px;

}
</style>
