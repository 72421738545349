export default [
  // {
  //   "attrGroupId": "1",
  //   "componentType": "TextBox",
  //   "sortOrder": "0",
  //   "meta": {
  //     "searchable": false,
  //     "required": false
  //   },
  //   "settings": {
  //     "type": "textarea",
  //     "maxLength": "1000"
  //   },
  //   "id": "70",
  //   "nameTxt": "Giới thiệu",
  //   "descriptionTxt": "Bổ sung thông tin, không điền lại các thông số. Tối đa 1000 ký tự."
  // },
  {
    "attrGroupId": "1",
    "componentType": "TextBox",
    "sortOrder": "1",
    "meta": {
      "searchable": false,
      "required": false
    },
    "settings": {
      "maxLength": "100",
      "type": "textbox"
    },
    "id": "71",
    "nameTxt": "Pass",
    "descriptionTxt": "Mật khẩu/Cách liên lạc"
  },
  {
    "attrGroupId": "1",
    "componentType": "DobBox",
    "sortOrder": "2",
    "meta": {
      "searchable": true,
      "required": true
    },
    "settings": {
      "minValue": "1979-12-31T17:00:00.000Z",
      "maxValue": "2004-11-30T17:00:00.000Z",
      "requireDate": false
    },
    "id": "42",
    "nameTxt": "nHome.birth",
    "descriptionTxt": "Chọn năm từ 1970 đến 1998"
  },
  {
    "attrGroupId": "2",
    "componentType": "NumberBox",
    "sortOrder": "3",
    "meta": {
      "searchable": true,
      "required": true
    },
    "settings": {
      "min": "140",
      "max": "180"
    },
    "id": "46",
    "nameTxt": "nHome.height",
    "descriptionTxt": "Nhập số từ 140 đến 180"
  },
  {
    "attrGroupId": "2",
    "componentType": "NumberBox",
    "sortOrder": "4",
    "meta": {
      "searchable": true,
      "required": true
    },
    "settings": {
      "min": "40",
      "max": "80"
    },
    "id": "48",
    "nameTxt": "nHome.weight",
    "descriptionTxt": "Nhập số từ 40 đến 80"
  },
  {
    "attrGroupId": "2",
    "componentType": "NumberBox",
    "sortOrder": "5",
    "meta": {
      "searchable": true,
      "required": true
    },
    "settings": {
      "min": "60",
      "max": "120"
    },
    "id": "49",
    "nameTxt": "nHome.circumference1",
    "descriptionTxt": "Nhập số từ 60 đến 120"
  },
  {
    "attrGroupId": "2",
    "componentType": "NumberBox",
    "sortOrder": "6",
    "meta": {
      "searchable": true,
      "required": true
    },
    "settings": {
      "min": "50",
      "max": "90"
    },
    "id": "50",
    "nameTxt": "nHome.circumference2",
    "descriptionTxt": "Nhập số từ 50 đến 90"
  },
  {
    "attrGroupId": "2",
    "componentType": "NumberBox",
    "sortOrder": "7",
    "meta": {
      "searchable": true,
      "required": true
    },
    "settings": {
      "min": "60",
      "max": "120"
    },
    "id": "51",
    "nameTxt": "nHome.circumference3",
    "descriptionTxt": "Nhập số từ 60 đến 120"
  },
  // {
  //   "attrGroupId": "1",
  //   "componentType": "RadioBox",
  //   "sortOrder": "8",
  //   "meta": {
  //     "searchable": true,
  //     "required": true
  //   },
  //   "settings": {
  //     "values": [
  //       {
  //         "sortOrder": 1,
  //         "bitValue": 1,
  //         "txtValue": "Miền Bắc"
  //       },
  //       {
  //         "sortOrder": 2,
  //         "bitValue": 2,
  //         "txtValue": "Miền Trung"
  //       },
  //       {
  //         "sortOrder": 3,
  //         "bitValue": 4,
  //         "txtValue": "Miền Nam"
  //       },
  //       {
  //         "sortOrder": 4,
  //         "bitValue": 8,
  //         "txtValue": "Miền Tây"
  //       }
  //     ],
  //     "bitPosition": 4
  //   },
  //   "id": "68",
  //   "nameTxt": "Xuất xứ",
  //   "descriptionTxt": "Chọn 1 trong 4"
  // },
  // {
  //   "attrGroupId": "2",
  //   "componentType": "RadioBox",
  //   "sortOrder": "9",
  //   "meta": {
  //     "searchable": true,
  //     "required": true
  //   },
  //   "settings": {
  //     "values": [
  //       {
  //         "sortOrder": 1,
  //         "bitValue": 1,
  //         "txtValue": "Rậm rạp"
  //       },
  //       {
  //         "sortOrder": 2,
  //         "bitValue": 2,
  //         "txtValue": "Gọn gàng"
  //       },
  //       {
  //         "sortOrder": 3,
  //         "bitValue": 4,
  //         "txtValue": "Lưa thưa"
  //       },
  //       {
  //         "sortOrder": 4,
  //         "bitValue": 8,
  //         "txtValue": "Cạo sạch"
  //       }
  //     ],
  //     "bitPosition": 4
  //   },
  //   "id": "69",
  //   "nameTxt": "Lông bím",
  //   "descriptionTxt": "Chọn 1 trong 4"
  // },
  // {
  //   "attrGroupId": "2",
  //   "componentType": "RadioBox",
  //   "sortOrder": "10",
  //   "meta": {
  //     "searchable": true,
  //     "required": true
  //   },
  //   "settings": {
  //     "values": [
  //       {
  //         "sortOrder": 1,
  //         "bitValue": 1,
  //         "txtValue": "Xinh xắn"
  //       },
  //       {
  //         "sortOrder": 2,
  //         "bitValue": 2,
  //         "txtValue": "Có nét"
  //       },
  //       {
  //         "sortOrder": 3,
  //         "bitValue": 4,
  //         "txtValue": "Bình thường"
  //       },
  //       {
  //         "sortOrder": 4,
  //         "bitValue": 8,
  //         "txtValue": "Hơi xấu"
  //       }
  //     ],
  //     "bitPosition": 4
  //   },
  //   "id": "67",
  //   "nameTxt": "Mặt",
  //   "descriptionTxt": "Chọn 1 trong 4"
  // },
  // {
  //   "attrGroupId": "3",
  //   "componentType": "ChoicesBox",
  //   "sortOrder": "11",
  //   "meta": {
  //     "searchable": true,
  //     "required": true
  //   },
  //   "settings": {
  //     "values": [
  //       {
  //         "sortOrder": 1,
  //         "bitValue": 1,
  //         "txtValue": "Hôn môi"
  //       },
  //       {
  //         "sortOrder": 2,
  //         "bitValue": 2,
  //         "txtValue": "Hôn vú"
  //       },
  //       {
  //         "sortOrder": "4",
  //         "bitValue": 4,
  //         "txtValue": "Vét máng"
  //       },
  //       {
  //         "sortOrder": "5",
  //         "bitValue": 8,
  //         "txtValue": "HJ, BJ"
  //       },
  //       {
  //         "sortOrder": "6",
  //         "bitValue": 16,
  //         "txtValue": "Dọn WC"
  //       },
  //       {
  //         "sortOrder": "7",
  //         "bitValue": 32,
  //         "txtValue": "Cum in alo"
  //       },
  //       {
  //         "sortOrder": "8",
  //         "bitValue": 64,
  //         "txtValue": "Chơi lỗ nhị"
  //       },
  //       {
  //         "sortOrder": "3",
  //         "bitValue": 256,
  //         "txtValue": "Bóp vú"
  //       },
  //       {
  //         "sortOrder": "11",
  //         "bitValue": 512,
  //         "txtValue": "Chơi some"
  //       },
  //       {
  //         "sortOrder": "12",
  //         "bitValue": 1024,
  //         "txtValue": "Qua đêm"
  //       }
  //     ],
  //     "type": "multiple",
  //     "bitPosition": 12
  //   },
  //   "id": "64",
  //   "nameTxt": "Service",
  //   "descriptionTxt": "Service nào gái phục vụ được 100% checker thì mới tick. Nếu không sẽ bị phạt khi checker \"báo xấu\"."
  // },
  // {
  //   "attrGroupId": "3",
  //   "componentType": "TextBox",
  //   "sortOrder": "13",
  //   "meta": {
  //     "searchable": false,
  //     "required": false
  //   },
  //   "settings": {
  //     "maxLength": "100",
  //     "type": "textbox"
  //   },
  //   "id": "73",
  //   "nameTxt": "Phụ phí",
  //   "descriptionTxt": "Ví dụ: Mặc đồ cosplay thêm 100k, Cafe trước thêm...,lỗ nhị thêm..., shot 2 thêm..., qua đêm..., đến chỗ khách thêm..., người nước ngoài thêm..., chơi some...v.v..."
  // },
  // {
  //   "attrGroupId": "1",
  //   "componentType": "TextBox",
  //   "sortOrder": "14",
  //   "meta": {
  //     "searchable": false,
  //     "required": true
  //   },
  //   "settings": {
  //     "maxLength": "200",
  //     "type": "textbox"
  //   },
  //   "id": "47",
  //   "nameTxt": "Nhận dạng",
  //   "descriptionTxt": "Ví dụ: hình xăm ở cổ tay trái"
  // },
  // {
  //   "attrGroupId": "3",
  //   "componentType": "TextBox",
  //   "sortOrder": "15",
  //   "meta": {
  //     "searchable": false,
  //     "required": false
  //   },
  //   "settings": {
  //     "type": "textbox",
  //     "maxLength": "50"
  //   },
  //   "id": "56",
  //   "nameTxt": "Thời lượng",
  //   "descriptionTxt": "Ví dụ: tối thiểu 40 phút"
  // },
  // {
  //   "attrGroupId": "3",
  //   "componentType": "TextBox",
  //   "sortOrder": "16",
  //   "meta": {
  //     "searchable": false,
  //     "required": false
  //   },
  //   "settings": {
  //     "type": "textbox",
  //     "maxLength": "50"
  //   },
  //   "id": "57",
  //   "nameTxt": "Hoạt động",
  //   "descriptionTxt": "Ví dụ: từ 8h đến 22h"
  // },
  {
    "attrGroupId": "3",
    "componentType": "TextBox",
    "sortOrder": "17",
    "meta": {
      "searchable": false,
      "required": false
    },
    "settings": {
      "type": "textbox",
      "maxLength": "100"
    },
    "id": "58",
    "nameTxt": "nHome.refuse",
    "descriptionTxt": "Ví dụ: Say xỉn, đập đá, bạo dâm..."
  }
]